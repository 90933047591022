import {Member} from "../../models/member.model";
import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {PengurusVerifyService} from "../../pengurus-view/pengurus-verify/pengurus-verify.service";
import {catchError, finalize, map} from "rxjs/operators";
import {SetLoadingState} from "../../states/loading.state";
import {UserService} from "../user.service";

export class LoadMemberDetail {
  static readonly type = '[User Profile] Load Member Detail';
}

export class UpdateUniversity {
  static readonly type = '[User Profile] Update University';
  constructor(public payload: any) {
  }
}

export class UpdateFaculty {
  static readonly type = '[User Profile] Update Faculty';
  constructor(public payload: any) {
  }
}

export class UpdateProdi {
  static readonly type = '[User Profile] Update Prodi';
  constructor(public payload: any) {
  }
}

export class RequestUpdateSK {
  static readonly type = '[User Profile] Request Update SK';
}

export interface UserProfileStateModel {
  member: Member;
}

@State<UserProfileStateModel>({
  name: 'UserProfileState',
  defaults: {
    member: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserProfileState {

  constructor(
    private pengurusVerifyService: PengurusVerifyService,
    private userService: UserService,
  ) {
  }

  @Action(LoadMemberDetail)
  public loadMemberDetail(ctx: StateContext<LoadMemberDetail>) {
    return this.pengurusVerifyService.getDetailMember().pipe(
      map((response) => ctx.setState({member: response.member})),
      map((response) => ctx.dispatch(new SetLoadingState(false))),
      catchError(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(UpdateUniversity)
  public updateUniversity(ctx: StateContext<UpdateUniversity>, action: UpdateUniversity) {
    return this.userService.updateUniversityData(action.payload).pipe(
      map((response) => ctx.dispatch(new LoadMemberDetail())),
      catchError(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(UpdateFaculty)
  public updateFaculty(ctx: StateContext<UpdateFaculty>, action: UpdateFaculty) {
    return this.userService.updateFacultyData(action.payload).pipe(
      map((response) => ctx.dispatch(new LoadMemberDetail())),
      catchError(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(UpdateProdi)
  public updateProdi(ctx: StateContext<UpdateProdi>, action: UpdateProdi) {
    return this.userService.updateProdiData(action.payload).pipe(
      map((response) => ctx.dispatch(new LoadMemberDetail())),
      catchError(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(RequestUpdateSK)
  public requestUpdateSK(ctx: StateContext<UserProfileStateModel>) {
    ctx.dispatch(new SetLoadingState(true))
    return this.userService.requestUpdateSK().pipe(
      finalize(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

}
