import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Dosen} from "../../../models/dosen.model";
import {LecturerService} from "./lecturer.service";
import {catchError, finalize, map} from "rxjs/operators";
import {SetLoadingState} from "../../../states/loading.state";

export class SetPagination {
  static readonly type = '[User Dosen] Set Pagination';

  constructor(
    public page: number,
    public size: number,
    public sortColumn: string,
    public sortDirection: string,
    public search: string,
    public type: string,
  ) {
  }
}

export class LoadDosenTable {
  static readonly type = '[User Dosen] Load Dosen Table';
}


export class CreateDosen {
  static readonly type = '[User Dosen] Create Dosen';

  constructor(
    public payload: any,
  ) {
  }
}

export class UpdateDosen {
  static readonly type = '[User Dosen] Update Dosen';

  constructor(
    public payload: any,
  ) {
  }
}

export class DeleteDosen {
  static readonly type = '[User Dosen] Delete Dosen';

  constructor(
    public id: string,
  ) {
  }
}

export interface UserDosenStateModel {
  table: UserDosenTableModel
  pagination: UserDosenPaginationModel
}

export interface UserDosenPaginationModel {
  page: number
  size: number
  sortColumn: string
  sortDirection: string
  search: string
  type: string
}

export interface UserDosenTableModel {
  pagination: {
    page: number,
    length: number
  },
  data: Dosen[]
}

@State<UserDosenStateModel>({
  name: 'UserDosenState',
  defaults: {
    table: {
      pagination: {
        page: 1,
        length: 0,
      },
      data: []
    },
    pagination: {
      page: 1,
      size: 0,
      sortColumn: 'id',
      sortDirection: 'desc',
      search: '',
      type: ''
    }
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserDosenState {

  constructor(
    private lecturerService: LecturerService,
  ) {
  }

  @Selector()
  static table(state: UserDosenStateModel) {
    return state.table;
  }

  @Selector()
  static pagination(state: UserDosenStateModel) {
    return state.pagination;
  }

  @Action(SetPagination)
  public setPagination(ctx: StateContext<UserDosenStateModel>, action: SetPagination) {
    const state = ctx.getState();
    ctx.setState({
      ...state, pagination: {
        page: action.page,
        size: action.size,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection,
        search: action.search,
        type: action.type
      }
    })
  }

  @Action(LoadDosenTable)
  public loadDosenTable(ctx: StateContext<UserDosenStateModel>, action: LoadDosenTable) {
    const state = ctx.getState();
    return this.lecturerService.getAllByMembers(
      state.pagination.page,
      state.pagination.size,
      state.pagination.sortColumn,
      state.pagination.sortDirection,
      state.pagination.search,
      state.pagination.type
    ).pipe(
      map((response) => ctx.setState({...state, table: response, })),
      map((response) => ctx.dispatch(new SetLoadingState(false))),
      catchError(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(CreateDosen)
  public createDosen(ctx: StateContext<CreateDosen>, action: CreateDosen) {
    ctx.dispatch(new SetLoadingState(true))
    return this.lecturerService.createDosen(action.payload).pipe(
      map((response) => ctx.dispatch(new LoadDosenTable())),
      finalize(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(UpdateDosen)
  public updateDosen(ctx: StateContext<UpdateDosen>, action: UpdateDosen) {
    ctx.dispatch(new SetLoadingState(true))
    return this.lecturerService.updateDosen(action.payload).pipe(
      map((response) => ctx.dispatch(new LoadDosenTable())),
      finalize(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }

  @Action(DeleteDosen)
  public deleteDosen(ctx: StateContext<DeleteDosen>, action: DeleteDosen) {
    ctx.dispatch(new SetLoadingState(true))
    return this.lecturerService.deleteDosen(action.id).pipe(
      map((response) => ctx.dispatch(new LoadDosenTable())),
      finalize(() => ctx.dispatch(new SetLoadingState(false)))
    )
  }
}
