import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LayoutModule} from '@angular/cdk/layout';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';

import {NgxsModule, Store} from '@ngxs/store';
import {AppState} from './app.state';

import {JwtModule} from '@auth0/angular-jwt';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {MyHttpInterceptor} from './core/http-interceptor';
import {UserState} from './user-view/user.state';
import {registerLocaleData} from '@angular/common';
import localeId from '@angular/common/locales/id';
import {LoadingState} from './states/loading.state';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MemberState} from './user-view/member.state';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {StoreModule} from "@ngrx/store";
import {rootReducer} from "./index.store";
import {EffectsModule} from "@ngrx/effects";
import {UserProfileState} from "./user-view/user-profile/user.profile.state";
import {UserDosenState} from "./user-view/lecturer/lecturer-list/lecturer.state";

registerLocaleData(localeId, 'id');

export function tokenGetter() {
  return '';
}

@NgModule({
  declarations: [AppComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatDialogModule,
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LayoutModule,
    NgxsModule.forRoot([AppState, UserState, MemberState, LoadingState, UserProfileState, UserDosenState], {
      developmentMode: !environment.production,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['app'],
    }),
    RecaptchaV3Module,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([

    ])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
      deps: [Store, MatSnackBar],
    },
    {provide: LOCALE_ID, useValue: 'id-ID'},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
