import {ActionReducerMap} from "@ngrx/store";


export interface RootReducerState {

}

export const rootReducer: ActionReducerMap<RootReducerState> = {

}
